import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  TextInput,
  Form,
  SaveButton,
  ImageInput,
  BooleanInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  TabbedForm,
  FormTab,
  SelectInput,
  DateInput,
} from "react-admin";
import { PreviewImage } from "../../utils/PreviewImage";

export const FormWOrders = () => {
  return (
    <Form label="Activity Type">
      <Grid container spacing={2} m={3}>
        <Grid item xs={4}>
          <DateInput
            variant="standard"
            source="order_date"
           
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            variant="standard"
            source="ammount"
            
            fullWidth
            validate={required()}
          />
        </Grid>
        
        <Grid item xs={6}>
          <SaveButton></SaveButton>
        </Grid>
      </Grid>
    </Form>
  );
};
