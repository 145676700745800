import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  TextInput,
  Form,
  SaveButton,
  ImageInput,
  required,
  email,
  useRecordContext,
} from "react-admin";
import { PreviewImage } from "../../utils/PreviewImage";

export const FormUserAdmin = () => {
  const record = useRecordContext();
  
  const emailConfirm = (value, allValues) => {
    if(value !== allValues.email)
      return 'Error';
    return undefined;
  };

  const validateEmail = [required(), email(), emailConfirm];

  return (
    <Form>
      <Grid container m={3} spacing={2} columnSpacing={3}>
        <Grid item xs={4}>
          <TextInput
            variant="standard"
            autofocus
            source="firstname"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            variant="standard"
            source="lastname"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid item xs={8}>
          <TextInput
            variant="standard"
            source="email"
            fullWidth
            validate={[email(), required()]}
          />
        </Grid>
        {!record && (
          <Grid item xs={8}>
            <TextInput
              variant="standard"
              source="email_confirm"
              fullWidth
              validate={validateEmail}
            />
          </Grid>
        )}
        <Grid item xs={8}>
          <ImageInput source="details.url_avatar" validate={required()} accept="image/*">
            <PreviewImage></PreviewImage>
          </ImageInput>
        </Grid>

        <Grid item xs={12}>
          <SaveButton />
        </Grid>
      </Grid>
    </Form>
  );
};
