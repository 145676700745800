// in src/posts.js
import * as React from "react";
import {
  useNotify,
} from "react-admin";
import { FormCity } from "./FormCity";
import Edit from "../../baseComponents/Edit";

export const CityEdit = () => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("ra.action.updated");
  };

  

  return (
    <Edit
      onSuccess={onSuccess}
    >
      <FormCity></FormCity>
    </Edit>
  );
};
