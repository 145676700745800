import * as React from "react";

import Create from "../../baseComponents/Create";
import { FormTerm } from "./FormTerm";

export const TermCreate = () => {
 
  
  return (
    <Create >
      <FormTerm></FormTerm>
    </Create>
  );
};
