import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  TextInput,
  Form,
  SaveButton,
  ImageInput,
  useRecordContext,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  useCreateSuggestionContext,
  useCreate,
  useChoicesContext,
  required
} from "react-admin";

import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { PreviewImage } from "../../utils/PreviewImage";
import { FormActivityType } from "../activityType/FormActivityType";
import { ActivityTypeCreate } from "../activityType/ActivityTypeCreate";

export const FormAchievement = () => {
  return (
    <Form>
      <Grid container m={3} spacing={2} columnSpacing={3}>
        <Grid item xs={10}>
          <TextInput variant="standard" autofocus source="name" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={10}>
          <ReferenceInput source="activity_type_id" reference="activityTypes">
            <SelectInput variant="standard" fullWidth optionText="name"  create={<CreateActivity />} validate={required()}/>
          </ReferenceInput>
        </Grid>
        <Grid container m={1} spacing={2} columnSpacing={3}>
          <Grid item xs={3}>
            <TextInput variant="standard" fullWidth source="target"></TextInput>
          </Grid>
          <Grid item xs={3}>
            <ReferenceInput source="measure_unit_id" reference="measureUnits">
              <SelectInput variant="standard" fullWidth optionText="name" />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container m={1} spacing={2} columnSpacing={3}>
          <Grid item xs={3}>
            <TextInput
              variant="standard"
              fullWidth
              source="calories"
              
            ></TextInput>
          </Grid>
          <Grid item xs={3}>
            <TextInput variant="standard" fullWidth source="amount"></TextInput>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <ImageInput source="url_icon" accept="image/*" validate={required()}>
            <PreviewImage></PreviewImage>
          </ImageInput>
        </Grid>

        <Grid item xs={12}>
          <SaveButton />
        </Grid>
      </Grid>
    </Form>
  );
};


const CreateActivity = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const {refetch} = useChoicesContext();
  const [openDialog, setOpen] = React.useState(true);
  const [create] = useCreate();

 

  const closeDialog =(data)=> {
    setOpen(false);
    console.log(data);
    refetch();
    onCreate(data);

  }
  return (
      <Dialog open={openDialog} onClose={onCancel}>
             <ActivityTypeCreate callback={closeDialog}></ActivityTypeCreate >
      </Dialog>
  );
};