import * as React from "react";

import Create from "../../baseComponents/Create";
import { FormUserAdmin } from "./FormUserAdmin";

export const UserAdminCreate = () => {
  const transformData = (data) => {
    if (data.details.url_avatar.rawFile)
      data = {
        ...data,
        avatarFile: data.details.url_avatar,
      };
    return data;
  };
  
  return (
    <Create transform={transformData}>
      <FormUserAdmin></FormUserAdmin>
    </Create>
  );
};
