import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  TextInput,
  Form,
  SaveButton,
  ImageInput,
  useRecordContext,
  required,
  email,
  FileInput,
  FileField,
} from "react-admin";
import { PreviewImage } from "../../utils/PreviewImage";

export const FormCharity = () => {
  const record = useRecordContext();

  const emailConfirm = (value, allValues) => {
    if (value !== allValues.email) return "Error";
    return undefined;
  };

  const validateEmail = [required(), email(), emailConfirm];

  return (
    <Form>
      <Grid container m={3} columnSpacing={3}>
        <Grid item xs={12} md={4}>
          {record && record.id && (
            <TextInput
              variant="standard"
              disabled={true}
              source="email"
              validate={email()}
              fullWidth
            />
          )}
          {!record && (
            <TextInput
              variant="standard"
              source="email"
              validate={email()}
              fullWidth
            />
          )}
        </Grid>

        {!record && (
          <Grid item xs={12} md={4}>
            <TextInput
              variant="standard"
              source="email_confirm"
              fullWidth
              validate={validateEmail}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <TextInput variant="standard" autofocus source="name" fullWidth />
        </Grid>
        <Grid item xs={12} md={10}>
          <TextInput
            multiline
            variant="standard"
            source="objectives"
            fullWidth
          />
        </Grid>
        <Grid item xs={10}>
          <TextInput multiline variant="standard" source="cause" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            variant="standard"
            source="min_withdrawal_amount"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
			 <TextInput
                variant="standard"
                fullWidth
                source="background"
              ></TextInput>
            </Grid>
            <Grid item xs={4}>
				<TextInput
                variant="standard"
                fullWidth
                source="text_color"></TextInput>
            </Grid>
        <Grid container m={1} columnSpacing={3}>

        <Grid item xs={8}>
          <ImageInput source="url_logo" accept="image/*">
            <PreviewImage></PreviewImage>
          </ImageInput>
        </Grid>
      
        <Grid item xs={8}>
          <ImageInput source="url_imagen" accept="image/*">
            <PreviewImage></PreviewImage>
          </ImageInput>
        </Grid>
        </Grid>

        <Grid item xs={6}>
          <TextInput variant="standard" source="firstname" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextInput variant="standard" source="lastname" fullWidth />
        </Grid>
		<Grid item xs={6}>
          <TextInput variant="standard" source="website" fullWidth />
        </Grid>
        <Grid item xs={8}>
          <FileInput source="url_letter" accept="application/pdf">
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>

        <Grid item xs={12}>
          <SaveButton />
        </Grid>
      </Grid>
    </Form>
  );
};
