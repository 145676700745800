import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  TextInput,
  Form,
  SaveButton,
  ImageInput,
  BooleanInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  TabbedForm,
  FormTab,
} from "react-admin";
import { PreviewImage } from "../../utils/PreviewImage";

export const FormActivityType = () => {
  return (
    <TabbedForm>
      <FormTab label="Activity Type">
        <Grid container m={3} spacing={2} columnSpacing={3}>
          <Grid item xs={8}>
            <TextInput
              variant="standard"
              source="name"
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs={8}>
            <BooleanInput source="map" validate={required()}></BooleanInput>
          </Grid>
          <Grid item xs={8}>
            <TextInput
              variant="standard"
              multiline={true}
              source="description"
              fullWidth
            />
          </Grid>
		  <Grid item xs={8}>
            <TextInput
              variant="standard"
              multiline={true}
              source="coefficient"
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <ImageInput
              source="url_icon"
              accept="image/*"
              validate={required()}
            >
              <PreviewImage></PreviewImage>
            </ImageInput>
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Mets">
        <Grid item xs={8}>
          <ArrayInput source="mets">
            <SimpleFormIterator disableReordering={true} >
             
                  <TextInput source="avg_speed" variant="standard" />
               
                  <TextInput source="met" variant="standard" />
               
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </FormTab>
    </TabbedForm>
  );
};
