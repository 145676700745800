import * as React from "react";
import Create from "../../baseComponents/Create";

import { FormChallenge } from "./FormChallenge";

const transformData = (data) => {
  if (data.url_picture.rawFile)
    data = {
      ...data,
      'pictureFile': data.url_picture,
      'backgroundFile':data.url_background

    };
  return data;
};


export const ChallengeCreate = () => (
  <Create transform={transformData}>
    <FormChallenge></FormChallenge>
  </Create>
);
