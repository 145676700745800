import * as React from "react";
import { List, Datagrid, TextField, EmailField, ShowButton, DateField, EditButton, FilterForm, FilterButton, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { Stack } from "@mui/material";

export const UserAdminList = () => (
    <List>
    <ListToolbar />

        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="firstname" />
            <TextField source="lastname" />
            <EmailField source="email" />
            <DateField source="created_at" />
            <ShowButton ></ShowButton>
            <EditButton></EditButton>

        </Datagrid>
    </List>
);


const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between">
      <FilterForm filters={adminFilters} />  
      {/* <div>
        <FilterButton filters={adminFilters} />
      </div> */}
    </Stack>
  );
  
  
  const adminFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
   
  ];
  