import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ShowButton,
  EditButton,
  BooleanField,
  ListContextProvider,
  useGetList,
  useList,
  Loading,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  DeleteButton,
  DeleteWithConfirmButton,
} from "react-admin";

export const CalorieCoefficientList = () => {
  const { data, isLoading,isFetching } = useGetList("calorieCoefficients", {
    pagination: { page: 1, perPage: 10 },
  });
  const listContext = useList({ data, isLoading,isFetching });
  const [dataM, setDataM] = React.useState([]);
  const [dataF, setDataF] = React.useState([]);

  React.useEffect(() => {
    if (!isLoading) {
      let dataMasc = [];
      let dataFem = [];
      data.forEach((el) => {
        if (el.gender === "F") {
          dataFem.push(el);
        } else {
          dataMasc.push(el);
        }
      });
      setDataF(dataFem);
      setDataM(dataMasc);
    }
  }, [data, isLoading]);

  if (isFetching) 
    return <Loading></Loading>;
  else{
    return (
      <ListContextProvider value={listContext}>
        <>
          <TopToolbar>
            <CreateButton />
            <ExportButton />
          </TopToolbar>
          <Datagrid  empty={false} filter={false}   sort={false}  data={dataM} bulkActionButtons={false}>
            <TextField source="init_age" />
            <TextField source="end_age" />
            <TextField source="gender" />
            <TextField source="coefficient" />
            <EditButton></EditButton>
            <DeleteWithConfirmButton></DeleteWithConfirmButton>
          </Datagrid>
  
          <Datagrid filter={false} sort={false} data={dataF} bulkActionButtons={false}>
            <TextField source="init_age" />
            <TextField source="end_age" />
            <TextField source="gender" />
            <TextField source="coefficient" />
            <EditButton></EditButton>
            <DeleteWithConfirmButton></DeleteWithConfirmButton>

          </Datagrid>
        </>
      </ListContextProvider>
    );
  }
  
};
