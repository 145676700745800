import * as React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import authProvider from "./auth/authProvider";
import CustomDataProvider from "./dataproviders/CustomDataProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import spanishMessages from "@blackbox-vision/ra-language-spanish";
import Dashboard from "./Dashboard";
import CustomLayout from "./layout/CustomLayout";
import LoginPage from "./auth/LoginPage";

import { customTheme } from "./layout/customTheme";
// domain translations
import { domainMessages } from "./lang/domainMessages";
import { Route } from "react-router-dom";
import ProfileCharity from "./resources/charity/ProfileCharity";
import Settings from "./Settings";

import users from "./resources/userApp";
import adminUsers from "./resources/userAdmin";
import charities from "./resources/charity";
import activityTypes from "./resources/activityType";
import achievements from "./resources/achievement";
import challenges from "./resources/challenge";
import measureUnits from "./resources/measureUnit";
import calorieCoefficients from "./resources/calorieCoefficient";
import withdrawalOrders from "./resources/withdrawalOrders";
import donations from "./resources/donations";
import countries from "./resources/country";
import cities from "./resources/city";
import terms from "./resources/terms";
import policy from "./resources/policy";
import TermsAndConditions from "./TermsAndConditions";
import Terms from "./TermsAndConditions";
import Privacy from "./PrivacyPolicy";

const messages = {
  es: { ...spanishMessages, ...domainMessages.es },
  en: { ...englishMessages, ...domainMessages.en },
};
const i18nProvider = polyglotI18nProvider((locale) => {
  return messages[locale];
});
const App = () => (
  <Admin
    locale="es"
    theme={customTheme}
    i18nProvider={i18nProvider}
    dataProvider={CustomDataProvider}
    authProvider={authProvider}
    loginPage={LoginPage}
    dashboard={Dashboard}
    layout={CustomLayout}
    disableTelemetry
  >
    <CustomRoutes noLayout> {/*Ver authProvider con publicRoutes*/}
      <Route path="/terms&conditions" element={<Terms />} />,
      <Route path="/privacypolicy" element={<Privacy />} />,
    </CustomRoutes>
    {(permissions) => {
      if (!permissions) return [];
      else
        return (
          <>
            {permissions.tieneRol("admin") ? (
              <>
                <Resource name="users" {...users} />
                <Resource name="admins" {...adminUsers} />
                <Resource name="charities" {...charities} />
                <Resource name="activityTypes" {...activityTypes} />
                <Resource name="achievements" {...achievements} />
                <Resource name="challenges" {...challenges} />

                <Resource name="measureUnits" {...measureUnits} />
                <Resource name="calorieCoefficients" {...calorieCoefficients} />
                <Resource name="withdrawalOrders" {...withdrawalOrders} />
                <Resource name="countries" {...countries} />
                <Resource name="cities" {...cities} />
                <Resource name="terms" {...terms} />
				        <Resource name="policies" {...policy} />
              </>
            ) : null}

            {permissions.tieneRol("charityUser") ? (
              <>
                <Resource name="withdrawalOrders" {...withdrawalOrders} />
                <Resource name="donations" {...donations} />
              </>
            ) : null}

            <CustomRoutes>
              {permissions.tieneRol("charityUser") ? (
                <>
                  <Route
                    path="/profile"
                    element={
                      <ProfileCharity id={localStorage.getItem("charity_id")} />
                    }
                  />
                </>
              ) : null}
              {permissions.tieneRol("admin") ? <></> : null}

              <Route path="/settings" element={<Settings />} />
            </CustomRoutes>
          </>
        );
    }}
  </Admin>
);

export default App;
