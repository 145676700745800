import * as React from "react";
import {
  Layout,
  AppBar,
  UserMenu,
  MenuItemLink,
  LocalesMenuButton,
  Logout,
  ToggleThemeButton,
} from "react-admin";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import logo from '../logo.png';
import {customTheme} from '../layout/customTheme';

const CustomAppBar = (props) => (
  <AppBar {...props} sx={{ bgcolor: "#1c1d1f" }} userMenu={<MyUserMenu />}>
    <img alt="logo" src={logo} style={{maxWidth: "100px",marginRight:"10px"}}></img>
    <Box flex="1">
      <Typography variant="h6" id="react-admin-title"></Typography>
    </Box>
    <ToggleThemeButton lightTheme={customTheme} darkTheme={darkTheme} />

    
  </AppBar>
);

const ConfigurationMenu = React.forwardRef(({ onClick }, ref) => (
  <MenuItemLink
    ref={ref}
    to="/configuration"
    primaryText="Configuration"
    onClick={onClick} // close the menu on click
  />
));

const VersionMenu = React.forwardRef(({ onClick }, ref) => (
  <MenuItemLink
    ref={ref}
    to=""
    primaryText={process.env.REACT_APP_VERSION}
    onClick={onClick} // close the menu on click
  />
));
const darkTheme = {
  palette: { mode: "dark" },
};

const MyUserMenu = (props) => (
  <UserMenu {...props} >
    <LocalesMenuButton
   
      languages={[
        { locale: "en", name: "English" },
        { locale: "es", name: "Español" },
      ]}
    />
    <VersionMenu />
    <Logout {...props} />
  </UserMenu>
);
const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;

export default CustomLayout;
