import * as React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import {
  Show,
  TextField,
  DateField,
  ReferenceField,
  Labeled,
} from "react-admin";

export const ChallengeShow = () => (
  <Show emptyWhileLoading>
    <Card >

    <Grid p={5} container spacing={2} mt={4} mb={4} columnSpacing={3}>
      <Grid item md={12}>
        <Labeled>
          <TextField variant="standard" autofocus source="name" fullWidth />
        </Labeled>
      </Grid>
      <Grid item md={12}>
        <Labeled>
            <TextField variant="standard"  fullWidth source="activity_type.name"/>
        </Labeled>
      </Grid>
      <Grid item md={4}>
        <Labeled>
          <TextField
            variant="standard"
            fullWidth
            source="target_duration"
          ></TextField>
        </Labeled>
      </Grid>
      <Grid item md={4}>
        <Labeled>
          <TextField
            variant="standard"
            fullWidth
            source="target_distance"
          ></TextField>
        </Labeled>
      </Grid>
      <Grid item md={4}>
        <ReferenceField source="measure_unit_id" reference="measureUnits">
          <Labeled>
            <TextField variant="standard" fullWidth optionText="name" />
          </Labeled>
        </ReferenceField>
      </Grid>
      <Grid item md={4}>
        <Labeled>
          <TextField
            variant="standard"
            fullWidth
            source="target_calories"
          ></TextField>
        </Labeled>
      </Grid>

      <Grid item md={4}>
        <Labeled>
          <TextField
            variant="standard"
            fullWidth
            source="timeframe"
          ></TextField>
        </Labeled>
      </Grid>
      
      <Grid item md={4}>
        <Labeled>
          <TextField
            variant="standard"
            fullWidth
            source="join_price"
          ></TextField>
        </Labeled>
      </Grid>
      <Grid item md={4}>
        <Labeled>
          <TextField
            variant="standard"
            fullWidth
            source="coin_award"
          ></TextField>
        </Labeled>
      </Grid>
      <Grid item md={4}>
        <Labeled>
          <TextField
            variant="standard"
            fullWidth
            source="stock_award"
          ></TextField>
        </Labeled>
      </Grid>
      <Grid item md={4}>
        <Labeled>
          <TextField
            variant="standard"
            fullWidth
            source="balance_award"
          ></TextField>
        </Labeled>
      </Grid>
      <Grid item md={4}>
        <Labeled>
          <DateField
            variant="standard"
            fullWidth
            source="public_init_date"
          ></DateField>
        </Labeled>
      </Grid>
      <Grid item md={4}>
        <Labeled>
          <DateField
            variant="standard"
            fullWidth
            source="public_end_date"
          ></DateField>
        </Labeled>
      </Grid>
    </Grid>
    </Card>
  </Show>
);
