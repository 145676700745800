import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  Show,
  TextField,
  DateField,
  Labeled,
  ImageField,
  BooleanField,
  ReferenceField,
} from "react-admin";

export const AchievementShow = () => (
  <Show emptyWhileLoading>
    <Grid container spacing={2} sx={{ margin: 2 }}>
      <Grid item xs={12} sm={12}>
        <Labeled>
          <TextField source="name" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Labeled>
          <ReferenceField source="activity_type_id" reference="activityTypes">
            <TextField variant="standard" fullWidth source="name" />
          </ReferenceField>
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Labeled>
          <TextField source="target" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Labeled>
          <ReferenceField source="measure_unit_id" reference="measureUnits">
            <TextField variant="standard" fullWidth source="name" />
          </ReferenceField>
        </Labeled>
      </Grid>
    
      <Grid item xs={12} sm={4}>
        <Labeled>
          <TextField source="calories" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Labeled>
          <TextField source="amount" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled>
          <DateField source="created_at" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={4} alignSelf="end">
        <ImageField className="RaImageField-image-preview" source="url_icon" />
      </Grid>
      
    </Grid>
  </Show>
);
