import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  TextInput,
  Form,
  SaveButton,
  required,
  NumberInput,
} from "react-admin";

export const FormCountry = () => {

  return (
    <Form>
      <Grid container m={3} spacing={2} columnSpacing={3}>
        <Grid item xs={4}>
          <TextInput
            variant="standard"
            autofocus
            source="name"
            fullWidth
            validate={required()}
          />
        </Grid>
       
        <Grid item xs={4}>
          <TextInput
            variant="standard"            
            source="code"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SaveButton />
        </Grid>
      </Grid>
    </Form>
  );
};
