import * as React from "react";
import Grid from "@mui/material/Grid";
import { Show, TextField, DateField, Labeled, ImageField} from 'react-admin';

export const UserAdminShow = () => (
    <Show emptyWhileLoading>
    <Grid container spacing={2} sx={{ margin: 2 }}>
        <Grid item xs={12} sm={6}>
        <Labeled>
            <TextField  source="firstname" />
            </Labeled>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Labeled>
            <TextField  source="lastname" />
            </Labeled>
        </Grid>
        
        <Grid item xs={12} sm={6}>
        <Labeled>
          <ImageField source="url_icon" className="RaImageField-image-preview"/>
        </Labeled>
      </Grid>
        <Grid item xs={12} sm={6}>
            <DateField  source="created_at" />
        </Grid>
       
        </Grid>

    </Show>
);