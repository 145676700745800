// in src/posts.js
import * as React from "react";
import {
  useNotify,
} from "react-admin";
import { FormUserAdmin } from "./FormUserAdmin";
import Edit from "../../baseComponents/Edit";

export const UserAdminEdit = () => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("ra.action.updated");
  };

  const transformData = (data) => {
    if(data.details.url_avatar.rawFile)
        data = {
            ...data,
            'avatarFile':data.details.url_avatar
        };
    return data; 
  };

  return (
    <Edit
      onSuccess={onSuccess}
      transform={transformData}
    >
      <FormUserAdmin></FormUserAdmin>
    </Edit>
  );
};
