import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import indigo from '@mui/material/colors/indigo';
import pink from '@mui/material/colors/pink';
import red from '@mui/material/colors/red';

export const customTheme = merge({}, defaultTheme, {
    palette: {
        primary: indigo,
        secondary: pink,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    components: {
        MuiButton: { // override the styles of all instances of this component
            styleOverrides: {
                root: { // Name of the rule
                 
                    bgColor:'#7364ff',
                    //bgColor:'#843c7f',
                },
            },
        },
        RaButton:{
           
            styleOverrides: {
                root: ({ ownerState }) => ({
                    '&:hover': {
                        backgroundColor:'#843c7f',
                        
                        color:'white'
                    }
                  }),
                 
            },
        },
        
        RaImageField:{
            
            styleOverrides: {
                root: ({ ownerState }) => ({
                    '& .RaImageField-image-preview > .RaImageField-image': {
                        maxWidth:"300px"
                    }
                  }),
                 
            },

        },
        RaImageInput:{
            
            styleOverrides: {
                root: ({ ownerState }) => ({
                    '& .RaImageField-image-preview > .RaImageField-image': {
                        maxWidth:"300px"
                    }
                  }),
                 
            },

        }
       
    },
});