import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ShowButton,
  EditButton,
  BooleanField,
  DateField,
  Loading,
  usePermissions,
  DeleteButton,
  Toolbar,
  TopToolbar,
  ListToolbar,
  ExportButton,
  CreateButton,
  SelectInput,
  DateInput,
  FilterButton,
} from "react-admin";

const listFilters = [
  <SelectInput
    variant="outlined"
    source="status"
    alwaysOn
    choices={[
      { id: "N", name: "Created", selected:true },
      { id: "R", name: "Release" },
      { id: "C", name: "Canceled" },
      { id: "A", name: "All" },

    ]}
  />,
  <DateInput label="Date" source="order_date" />,


];

export const WOrdersList = () => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) return <Loading></Loading>;

  const ListActions = () => (
    <>
      <TopToolbar>
        <FilterButton></FilterButton>
        {permissions.tieneRol("charityUser") && <CreateButton></CreateButton>}
        <ExportButton />
      </TopToolbar>
    </>
  );
  return (
    <List empty={false} filters={listFilters} filterDefaultValues={{ status: "N" }} actions={<ListActions />}>
      {permissions.tieneRol("charityUser") && (
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="WO#" />
          <DateField source="order_date" />
          <TextField source="ammount" />
          <TextField source="status_desc" />
          <DeleteButton></DeleteButton>
        </Datagrid>
      )}
      {permissions.tieneRol("admin") && (
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="WO#" />
          <DateField source="order_date" />
          <TextField source="type" />
          <TextField source="name" />
          <TextField source="ammount" />
          <TextField source="status_desc" />
          <ShowButton></ShowButton>
        </Datagrid>
      )}
    </List>
  );
};
