// in src/posts.js
import * as React from "react";
import {
    useNotify,
} from "react-admin";
import { FormPolicy } from "./FormPolicy";
import Edit from "../../baseComponents/Edit";

export const PolicyEdit = () => {
    const notify = useNotify();

    const onSuccess = () => {
        notify("ra.action.updated");
    };

    return ( <Edit onSuccess = { onSuccess }>
        <FormPolicy></FormPolicy></Edit>
    );
};