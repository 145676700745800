import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import {
  TextInput,
  Form,
  SaveButton,
  required,
  NumberInput,
} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';

export const FormPolicy = () => {
  return (
    <Container>
      <Form>
        <Grid container mt={3} mb={3} spacing={2} columnSpacing={3}>
          <Grid item md={6}>
            <NumberInput
              variant="standard"
              autofocus
              source="version"
              fullWidth
              validate={required()}
            />
          </Grid>

          <Grid item md={12}>
            <RichTextInput
              
              source="description"
            
              validate={required()}
            />
          </Grid>
        
          <Grid item md={12}>
            <SaveButton />
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};
