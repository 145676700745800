const axios = require('axios').default;

const publicRoutes = [
    '#/terms&conditions',
    '#/privacypolicy'
]
const authProvider = {

    login: ({ email, password }) => {

        
        return apiClient.get(process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie')
            .then(() => {
                return apiClient.post(process.env.REACT_APP_API_URL + '/login', { email, password })
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        if(response.data.data){
                            let data = response.data.data;
                            localStorage.removeItem('auth');
                            localStorage.setItem('auth', JSON.stringify(data.user));
                            localStorage.setItem('user_id', data.user.id);
                            localStorage.setItem('charity_id', data.charity ? data.charity.id :null );
                            if(data.user && data.user.permissions){
                                localStorage.removeItem('permisos');
                                localStorage.setItem('permisos', JSON.stringify(data.user.permissions));

                            }
                           
                        }
                        return response;
                    })

            })
            .catch((error) => {
                console.log(error.response.data);
                if (error.response.data && error.response.data.message) {
                    throw new Error(error.response.data.message);

                } else {
                    throw new Error('Error inesperado');

                }
            });


    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: params => {
        if (publicRoutes.includes(window.location.hash)) {
            return Promise.resolve();
        }
        return localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject()
    },
    logout: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('roles');
        localStorage.removeItem('permisos');
        localStorage.removeItem('charity_id');

        apiClient.post(process.env.REACT_APP_API_URL + '/logout');
        return Promise.resolve();
    },
    getIdentity: () => {
        const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth'));
        return { id, fullName, avatar };
    },
    getPermissions: params => {
        let roles = localStorage.getItem('roles'); //TODO hashear el item roles y permisos
        let permisos = JSON.parse(localStorage.getItem('permisos'));

        if (publicRoutes.includes(window.location.hash)) {
            return Promise.resolve();
        }
        const rolesFn = {
            tieneRol: (nombreRol) => {
                let roles = JSON.parse(localStorage.getItem('roles'));
                return roles.find(rol => {
                    return rol === nombreRol;
                }) ? true : false;
            },
            can: (accion) => {
                let permisos = JSON.parse(localStorage.getItem('permisos'));
                return permisos.find(permiso => {
                    return permiso === accion;
                }) ? true : false;
            }

        };
        if (roles && permisos) {
            return Promise.resolve(rolesFn);
        }

        return apiClient.get(process.env.REACT_APP_API_URL + '/roles')
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                localStorage.setItem('roles', JSON.stringify(response.data.data));
                return rolesFn;
            })

            .catch((error) => {
                if (error.response && error.response.data.errors) {
                    throw new Error(error.response.data.errors);
                } else {
                    throw new Error('Error inesperado');
                }
            });
    }
    ,
   
    checkUser: ({ email }) => {

        return apiClient.get(process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie')
            .then(() => {
                return apiClient.post(process.env.REACT_APP_API_URL + '/checkUser', { email })
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        return response;
                    })
            })
            .catch((error) => {
                if (error.response && error.response.data.errors) {
                    throw new Error(error.response.data.errors);
                } else {
                    throw new Error('Error inesperado');
                }
            });


    },
    verifyEmail: ({ email, password, id, hash, expire, signature }) => {


        return apiClient.get(process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie')
            .then(() => {
                return apiClient.post(process.env.REACT_APP_API_URL + '/login', { email, password })
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        return apiClient.get(process.env.REACT_APP_API_URL + '/email/verify/' + id + '/' + hash + '?expires=' + expire + '&signature=' + signature)
                            .then(response => {
                                if (response.status < 200 || response.status >= 300) {
                                    throw new Error(response.statusText);
                                }
                                return response;
                            });
                    })

            })
            .catch((error) => {
                if (error.response.data && error.response.data.data.errors.detail) {
                    throw new Error(error.response.data.data.errors.detail);

                } else {
                    throw new Error('Error inesperado');

                }
            });



    },

};


const apiClient = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    withCredentials: true,
});




export default authProvider;