import {TermList} from './TermList';
import {TermEdit} from './TermEdit';
import { TermShow } from './TermShow';
import { TermCreate } from './TermCreate';
import GavelIcon from '@mui/icons-material/Gavel';

export default {
    edit:TermEdit,
    list:TermList,
    show:TermShow,
    create:TermCreate,
    icon:GavelIcon
};