import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  TextInput,
  Form,
  SaveButton,
  ImageInput,
  BooleanInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  TabbedForm,
  FormTab,
  SelectInput,
} from "react-admin";
import { PreviewImage } from "../../utils/PreviewImage";

export const FormCalorieCoefficient = () => {
  return (
    <Form label="Activity Type">
      <Grid container spacing={2} m={3}>
        <Grid item xs={4}>
          <NumberInput
            variant="standard"
            source="init_age"
            min="1"
            max="110"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            variant="standard"
            source="end_age"
            min="1"
            max="110"
            fullWidth
            validate={required()}
          />
        </Grid>
        </Grid>
        <Grid container spacing={2} m={3}>

        <Grid item xs={4}>
          <SelectInput
            variant="standard"
            source="gender"
            fullWidth
            validate={required()}
            choices={[
              { id: "M", name: "Male" },
              { id: "F", name: "Female" },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            variant="standard"
            source="coefficient"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid item xs={6}>
          <SaveButton></SaveButton>
        </Grid>
      </Grid>
    </Form>
  );
};
