import * as React from 'react';
import { createElement } from 'react';
import { Card, Box, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { TextField } from 'react-admin';



const CardWithSource = (props) => {
    const { icon, title, subtitle, to, children, source } = props;

    return (
        // @ts-ignore
        <Card
            sx={{
                minHeight: 52,
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            }}
        >
            <Link to={to}>
                <Box
                    sx={{
                        overflow: 'inherit',
                        padding: '16px',                        
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        
                    }}
                >
                    <Box width="5em" className="icon">
                        {createElement(icon, { fontSize: 'large' })}
                    </Box>
                    <Box textAlign="right">
                        <Typography color="textSecondary">{title}</Typography>
                        
                        <TextField variant='h5' component="h2" source={source}></TextField>
                    </Box>
                </Box>
            </Link>
            {children && <Divider />}
            {children}
        </Card>
    );
};

export default CardWithSource;