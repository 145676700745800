import * as React from "react";
import Grid from "@mui/material/Grid";
import { Show, TextField, DateField, Labeled, ImageField, BooleanField } from "react-admin";

export const CalorieCoefficientShow = () => (
  <Show emptyWhileLoading>
    <Grid container spacing={2} sx={{ margin: 2 }}>
      
      <Grid item xs={12} sm={6}>
        <Labeled>
          <TextField source="name" />
        </Labeled>
      </Grid>
      
   
      <Grid item xs={12} sm={6}>
        <Labeled>
          <BooleanField source="map" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled>
          <DateField source="created_at" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled>
          <TextField source="description" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled>
          <ImageField className="RaImageField-image-preview" source="url_icon"   />
        </Labeled>
      </Grid>
   
     
    </Grid>
  </Show>
);
