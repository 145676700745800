import * as React from "react";
import Create from "../../baseComponents/Create";


import { FormCharity } from "./FormCharity";

const transformData = (data) => {
  if (data.url_logo && data.url_logo.rawFile) data["logoFile"] = data.url_logo;
  if (data.url_imagen && data.url_imagen.rawFile) data["imagenFile"] = data.url_imagen;
  if (data.url_letter && data.url_letter.rawFile) data["letterFile"] = data.url_letter;
  return data; 
};
export const CharityCreate = () => (
  <Create transform={transformData}>
    <FormCharity></FormCharity>
  </Create>
);
