import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  DateField,
  EditButton,
  ReferenceField,
  BooleanField,
} from "react-admin";

export const TermList = () => (
  <List>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="version" />
      <BooleanField source="active"/>
      <DateField source="created_at" />
      <ShowButton></ShowButton>
      <EditButton></EditButton>
    </Datagrid>
  </List>
);
