// in src/posts.js
import * as React from "react";
import {  
  useNotify,
} from "react-admin";
import Edit from "../../baseComponents/Edit";
import { FormCalorieCoefficient } from "./FormCalorieCoefficient";


export const CalorieCoefficientEdit = () => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("resources.CalorieCoefficient.action.updated");
  };

  
  return (
    <Edit
       onSuccess={onSuccess}
    >
      <FormCalorieCoefficient></FormCalorieCoefficient>
    </Edit>
  );
};
