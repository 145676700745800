import * as React from "react";

import Create from "../../baseComponents/Create";
import { FormPolicy } from "./FormPolicy";

export const PolicyCreate = () => {


    return ( <Create>
        <FormPolicy></FormPolicy>
	</Create>
    );
};