import {ChallengeList} from './ChallengeList';
import { ChallengeShow } from './ChallengeShow';
import {ChallengeCreate} from './ChallengeCreate';
import { ChallengeEdit } from './ChallengeEdit';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
export default {
    list:ChallengeList,
    show:ChallengeShow,
    create:ChallengeCreate,
    edit:ChallengeEdit,
    icon:SportsScoreIcon
};