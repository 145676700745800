import {UserAdminList} from './UserAdminList';
import {UserAdminEdit} from './UserAdminEdit';
import { UserAdminShow } from './UserAdminShow';
import { UserAdminCreate } from './UserAdminCreate';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
export default {
    edit:UserAdminEdit,
    list:UserAdminList,
    show:UserAdminShow,
    create:UserAdminCreate,
    icon:AdminPanelSettingsIcon
};