export const domainMessages = {
  es: {
    auth: {
      user_menu: "Perfil",
      email: "Email",
      password: "Contraseña",
      sign_in_error: "Error al autenticar, vuelva a intentar",
      logout: "Salir",
      forgot_password: "Olvide mi contraseña",
      remember_me: "Recordarme",
    },
    dashboard: {
      total_users: "Cantidad de Usuarios",
      total_calories_charities:
        "Total de Calories Coins donadas a Beneficiencias",
      total_calories_users: "Total  de Calories Coins entregadas a Usuarios",
      estimated_price: "Precio Estimado",
      total_charities: "Cantidad de Entidades Beneficas",
      total_active_users:"Total de Usuarios activos",
      delivered: "Entregadas",
      total_delivered_users:"Total dinero acumulado por Usuarios",
      total_delivered_charities:"Total dinero acumulado por Entidades",
      BPM_available:"BPM disponibles",
      BPM_balance:"BPM entregados",
      total_pending_withdraw_orders:'Ordenes de Retiro Pendientes',
      active_challenges:'Desafios activos'

    },
    resources: {
      users: {
        name: "Usuario APP |||| Usuarios APP",
        fields: {
          details: {
            sex: "Genero",
            birth_date: "Fecha de Nacimiento",
            height: "Altura",
            weight: "Peso",
            cell:"Celular",
            country:"Pais"
          },
          firstname: "Nombre",
          lastname: "Apellido",
          created_at: "Fecha Registro",
          updated_at: "Ultimo uso",
          platform: "Plataforma",
          activities: "Actividades",
          achievements: "Logros",
          challenges: "Desafios",
          total_distance: "Distancia Total",
          total_calories: "Total de Calorias",
          total_time: "Tiempo Total",
          balance: "Balance",
          age: "Edad",
          sex: "Genero",
          validated_at: "Fecha Validado",
          validated: "Validado",
        },
        total_coins_donated: "Coins Donadas",
        balance: "Balance",
        tabs:{
          orders:'Ordenes de Retiro',
          details:'Detalles',
          activities:'Actividades',
          
        }
      },
      admins: {
        name: "Usuario administrador |||| Administradores",
        fields: {
          firstname: "Nombre",
          lastname: "Apellido",
          picture: "Foto",
          created_at: "Fecha Registro",
        },
        action: {
          created: "Admin created",
          updated: "Admin updated",
          deleted: "Admin deleted",
        },
      },
      charities: {
        name: " Entidad Benéfica |||| Entidades Benéficas ",
        fields: {
          name: "Nombre",
          cause: "Causa",
          logo: "Logo",
          url_logo: "Logo",
          url_letter: "Carta de autorización",
          objectives: "Objetivos",
          firstname: "Nombre Responsable",
          lastname: "Apellido Responsable",
		  website: "Sitio web",
          email: "Email",
          worder_pending:"Ordenes Pendientes",
          worder_released:"Ordenes Realizadas"
        },
        total_coins: "Cantidad de Calorie Coins",
        tabs: {
          info: "Información",
          users: "Usuarios",
          donations: "Ultimas Donaciones",
        },
        action: {
          created: "Charity created",
          updated: "Charity updated",
          deleted: "Charity deleted",
        },
      },
      activityTypes: {
        name: " Tipo de Actividad |||| Tipo de Actividades ",
        fields: {
          name: "Nombre",
          logo: "Logo",
          map: "Considera Mapa",
          avg_speed:"Velocidad Promedio",
          met:"MET",
        },
        action: {
          created: "Tipo de Actividad creada",
          updated: "Tipo de Actividad actualizada",
          deleted: "Tipo de Actividad eliminada",
        },
      },
      measureUnits: {
        name: " Unidad de Medida  |||| Unidades de medida ",
        fields: {
          name: "Nombre",
          desccription: "Descripción",
        },
      },
      challenges: {
        name: "Desafio |||| Desafios",
        fields: {
          name: "Nombre",
          activity_type_id: "Tipo de Actividad",
          target_duration: "Duración",
          target_distance: "Distancia",
          measure_unit_id: "Unidad de Medida",
          target_calories: "Calorias",
          public_init_date: "Fecha Inicio",
          public_end_date: "Fecha Fin",
          timeframe: "Tiempo",
          coin_award: "Recompensa",
          url_picture: "Logo",
          activity_type: {
            name: "Tipo de Actividad",
          },
        },
      },
      donations: {
        name: "Donación |||| Donaciones",
        fields: {
          user: {
            firstname: "Nombre",
            lastname: "Apellido",
            email: "Email",
          },

          picture: "Foto",
          created_at: "Fecha Registro",
          updated_at: "Fecha Donación",
          amount: "Monto",
          status: "Estado",
        },
      },
      achievements: {
        name: " Logro |||| Logros ",
        fields: {
          name: "Nombre",
          icon: "Icono",
          activity_type_id: "Tipo de Actividad",
          measure_unit_id: "Unidad de Medida",
          measure_unit: "Unidad de Medida",
          amount: "Monto",
          calories: "Calorias",
          created_at: "Fecha Carga",
        },
      },
      calorieCoefficients: {
        name: " Coeficiente ||||  Coeficientes",
        fields: {
          init_age: "Edad Inicial",
          end_age: "Edad Final",
          gender:"Genero",
          coefficient:"Coeficiente",
        },
        action: {
          created: "Coeficiente creado",
          updated: "Coeficiente actualizado",
          deleted: "Coeficiente eliminado",
        },
      },
      withdrawalOrders: {
        name: " Orden de retiro  ||||   Ordenes de retiro",
        fields: {
          ammount: "Cantidad",
          order_date: "Fecha",
          status:"Estado",
        },
       
      },
      terms: {
        name: " Terminos y condiciones  ||||   Terminos y condiciones",
        fields: {
          description: "Descripción",
          version: "Version",
          active:"Activo",
        },
       
      },
	  policy: {
        name: " Política de Privacidad  ||||   Política de Privacidad",
        fields: {
          description: "Descripción",
          version: "Version",
          active:"Activo",
        },
       
      },
    },
  },
  en: {
    auth: {
      auth_check_error: "Please login to continue",
      user_menu: "Profile",
      email: "Email",
      password: "Password",
      sign_in: "Sign in",
      sign_in_error: "Authentication failed, please retry",
      logout: "Logout",
      forgot_password: "Forgot Password",
      remember_me: "Remember me",
    },
    dashboard: {
      total_calories_charities: "Total Calories Charities",
      total_calories_users: "Total Calories Users",
      total_users: "Total Users",
      total_charities: "Total Charities",
      estimated_price: "BPM Quotation",
      delivered: "Delivered",
      total_active_users:"Total Active Users",
      total_delivered_users:"Total Earned Users",
      total_delivered_charities:"Total Earned Charities",
      BPM_available:"BPM available",
      BPM_balance:"BPM delivered",
      total_pending_withdraw_orders:'Pending withdraw orders',
      active_challenges:'Active Challenges'

    },
    resources: {
      users: {
        name: "User |||| Users",
        fields: {
          activities: "Activities",
          achievements: "Achievements",
          challenges: "Challenges",
          total_distance: "Total Distance",
          total_calories: "Total Calories",
          total_time: "Total Time",
          balance: "Balance",
          details: {
            sex: "Sex",
            birth_date: "Birth Date",
            height: "Height",
            weight: "Weight",
            cell:"Cell",
            country:"Country"
          },
        },
        total_coins_donated: "Donated Coins",
        balance: "Balance",
        tabs:{
          orders:'Orders',
          details:'Details',
          activities:'Activities',
          
        }
      },
      admins: {
        action: {
          created: "Admin created",
          updated: "Admin updated",
          deleted: "Admin deleted",
        },
      },
      activityTypes: {
        name: " Activity Type  ||||  Activity Types",
        fields: {
          url_icon: "Icon",
          map: "Required Map",
          avg_speed:"Average speed",
          met:"MET",
        },
        action: {
          created: "Activity Type created",
          updated: "Activity Type updated",
          deleted: "Activity Type deleted",
        },
      },
      measureUnits: {
        name: " Measure Unit  |||| Measure Units ",
      },
      charities: {
        tabs: {
          info: "Information",
          users: "Users",
          donations: "Last Donations",
        },
        fields: {
          url_logo: "Logo",
          worder_pending:"Pending Orders",
          worder_released:"Released Orders"
        },
        total_coins: "Total Calorie Coins",
        action: {
          created: "Charity created",
          updated: "Charity updated",
          deleted: "Charity deleted",
        },
      },
      challenges: {
        fields: {
          activity_type: {
            name: "Activity type",
            url_picture: "Logo",
          },
        },
      },
      donations: {
        fields: {
          user: {
            firstname: "FirstName",
            lastname: "LastName",
            email: "Email",
          },
          updated_at: "Donated At",
        },
      },
      calorieCoefficients: {
        name: " Calorie Coefficient  ||||  Calorie Coefficients",
        fields: {
          init_age: "Init Age",
          end_age: "End Age",
          gender:"Gender",
          coefficient:"Coefficient",
        },
        action: {
          created: "Calorie Coefficient created",
          updated: "Calorie Coefficient updated",
          deleted: "Calorie Coefficient deleted",
        },
      },
      withdrawalOrders: {
        name: " Withdrawal Order  ||||   Withdrawal Orders",
        
       
      },
      terms: {
        name: " Terms and Conditions  ||||  Terms and Conditions",
       
       
      },
	  policy: {
        name: " Privacy Policy  ||||  Privacy Policy",
       
       
      },
    },
  },
};
