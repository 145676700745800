// in src/posts.js
import * as React from "react";
import {  
  useNotify,
} from "react-admin";
import Edit from "../../baseComponents/Edit";
import { FormActivityType } from "./FormActivityType";


export const ActivityTypeEdit = () => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("resources.ActivityTypes.action.updated");
  };

  const transformData = (data) => {
    if(data.url_icon.rawFile)
        data = {
            ...data,
            'iconFile':data.url_icon
        };
    return data; 
  };

  return (
    <Edit
       onSuccess={onSuccess}
      transform={transformData}
    >
      <FormActivityType></FormActivityType>
    </Edit>
  );
};
