// in src/posts.js
import * as React from "react";
import { useNotify } from "react-admin";
import Edit from "../../baseComponents/Edit";
import { FormChallenge } from "./FormChallenge";

const transformData = (data) => {
  if (data.url_picture && data.url_picture.rawFile) data["pictureFile"] = data.url_picture;

  if (data.url_background && data.url_background.rawFile) data["backgroundFile"] = data.url_background;

  return data;
};

export const ChallengeEdit = () => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("resources.challenges.action.updated");
  };

  return (
    <Edit onSuccess={onSuccess} transform={transformData}>
      <FormChallenge></FormChallenge>
    </Edit>
  );
};
