import * as React from "react";

import Create from "../../baseComponents/Create";
import { FormCountry } from "./FormCountry";

export const CountryCreate = () => {
 
  
  return (
    <Create >
      <FormCountry></FormCountry>
    </Create>
  );
};
