import {CityList} from './CityList';
import {CityEdit} from './CityEdit';
import { CityShow } from './CityShow';
import { CityCreate } from './CityCreate';
import LocationCityIcon from '@mui/icons-material/LocationCity';

export default {
    edit:CityEdit,
    list:CityList,
    show:CityShow,
    create:CityCreate,
    icon:LocationCityIcon
};