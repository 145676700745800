import * as React from "react";
import {
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  BooleanField,
  SelectInput,
  FunctionField,
  Button,
  useDataProvider,
  
  DeleteWithConfirmButton,
} from "react-admin";
import List from "../../baseComponents/List";
import { useRefresh } from 'react-admin';

const listFilters = [
  <SelectInput
    variant="outlined"
    source="status"
    alwaysOn
    choices={[
      { id: "active", name: "Active", selected:true },
      { id: "deleted", name: "To restore" },
    ]}
  />,
];

const RestoreButton = ({ record }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
  const restoreId = () => {
    dataProvider.restore('activityTypes',record)
    
    .then(({ data }) => {
        console.log(data);
        refresh();
    })
    .catch(error => {
        console.log('error');
        
    })
  };

  return <Button onClick={restoreId}>Restore</Button>;
};

export const ActivityTypeList = () => {
  return (
    <List empty={false} filters={listFilters} filterDefaultValues={{ status: "active" }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <BooleanField source="map" />
        <ShowButton></ShowButton>
        <FunctionField
          render={(record) => {
            if (!record.deleted_at) 
            return  <EditButton></EditButton>;
          }}
        ></FunctionField>
        <FunctionField
          render={(record) => {
            if (record.deleted_at)
              return <RestoreButton record={record}></RestoreButton>;
            else return  <DeleteWithConfirmButton/>
            ;
          }}
        />
      </Datagrid>
    </List>
  );
};
