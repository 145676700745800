import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ShowButton,
  EditButton,
  BooleanField,
  DateField,
  Loading,
  usePermissions,
  DeleteButton,
  TopToolbar,
  ImageField,
} from "react-admin";

export const DonationsList = () => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) return <Loading></Loading>;

  return (
    <List empty={false}  actions={<TopToolbar/> }>
      <Datagrid bulkActionButtons={false}>
        <ImageField source="avatar"/>
        <TextField source="name" />
        <TextField source="amount" />
        <TextField source="status" />
        <DateField source="updated_at" />
      </Datagrid>
    </List>
  );
};
