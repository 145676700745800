import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  Show,
  TextField,
  DateField,
  Labeled,
  TabbedShowLayout,
  Tab,
  ImageField,
  useRecordContext,
  useGetManyReference,
  useTranslate,
  ReferenceManyField,
  Datagrid,
  EmailField,
  UrlField,
  FileField,
} from "react-admin";

export const CharityShow = () => {
  return (
    <Show emptyWhileLoading>
      <TabbedShowLayout>
        <Tab sx={{ maxWidth: "40em" }} label="resources.charities.tabs.info">
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2} mr={2}>
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField variant="h5" source="name" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <EmailField source="email" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Labeled>
                        <TextField source="cause" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Labeled>
                        <TextField source="objectives" />
                      </Labeled>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <Labeled>
                        <TextField source="firstname" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Labeled>
                        <TextField source="lastname" />
                      </Labeled>
                    </Grid> 
                    <Grid item xs={12} sm={6}>
                      <Labeled>
                        <FileField source="url_letter" title="show" target="_blank" />

                      </Labeled>
                    </Grid>       
                    <Grid item xs={12} sm={6}>
                      <Labeled>
                        <TextField source="min_withdrawal_amount" />
                      </Labeled>
                    </Grid>         
                          
                    <Grid item xs={12} sm={6}>
                      <Labeled>
                        <DateField source="created_at" />
                      </Labeled>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            
            </Grid>

            <Grid item xs={12} sm={4} alignSelf="end">
              <ImageField
                className="RaImageField-image-preview"
                source="url_logo"
              />
            </Grid>
            <Grid item xs={12} sm={4} alignSelf="end">
              <ImageField
                className="RaImageField-image-preview"
                source="url_imagen"
              />
            </Grid>
          </Grid>
        </Tab>
        <UsersTab>
          <ReferenceManyField label="" reference="donations" target="charity_id">
            <Datagrid
              bulkActionButtons={false}
              
            >
              <TextField source="user.firstname" />
              <TextField source="user.lastname" />
              <EmailField source="user.email" />
              <TextField source="amount" />
              <TextField source="status" />

              <DateField source="updated_at" />
            </Datagrid>
          </ReferenceManyField>
        </UsersTab>
      </TabbedShowLayout>
    </Show>
  );
};

const UsersTab = (props) => {
  const record = useRecordContext();
  const { isLoading, total } = useGetManyReference(
    "donations",
    {
      target: "charity_id",
      id: record.id,
      pagination: { page: 1, perPage: 25 },
      sort: { field: "id", order: "DESC" },
    },
    {
      enabled: !!record,
    }
  );
  const translate = useTranslate();
  let label = translate("resources.charities.tabs.donations");

  return <Tab label={label} {...props} />;
};
