// in src/posts.js
import * as React from "react";
import Edit from "../../baseComponents/Edit";
import { FormUser } from "./FormUser";

export const UserEdit = () => {
  
  return (
    <Edit>
      <FormUser></FormUser>
    </Edit>
  );
};
