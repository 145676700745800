import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ReferenceField,
  TextInput,
  FilterButton,
  FilterForm,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { Stack } from "@mui/material";

export const AchievementList = () => (
  <List empty={false}>
    <ListToolbar />
    <Datagrid rowClick="show" bulkActionButtons={false}>
    <ReferenceField source="activity_type_id" reference="activityTypes">
        <TextField source="name"></TextField>
      </ReferenceField>
      <TextField source="name" />
      <TextField source="target" />    
      <ReferenceField source="measure_unit_id" reference="measureUnits">
        <TextField source="name"></TextField>
      </ReferenceField>
      <TextField source="calories" />
      <TextField source="amount" />

      <ShowButton></ShowButton>
      <EditButton></EditButton>
    </Datagrid>
  </List>
);

const ListToolbar = () => (
  <Stack direction="row" justifyContent="space-between">
    <FilterForm filters={achievementsFilters} />

    <div>
      <FilterButton filters={achievementsFilters} />
    </div>
  </Stack>
);


const achievementsFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput source="activity_type_id" reference="activityTypes">
    <SelectInput source="name"></SelectInput>
  </ReferenceInput>,
    <ReferenceInput source="measure_unit_id" reference="measureUnits">
    <SelectInput source="name"></SelectInput>
  </ReferenceInput>,
  <TextInput label="Target" source="target" />,
  <TextInput label="Calories" source="calories" />,
  <TextInput label="Amount" source="amount" />,
];
