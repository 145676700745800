import * as React from "react";
import { Grid, Box, Card, CardContent, Typography } from "@mui/material/";
import {
  Show,
  TextField,
  DateField,
  Labeled,
  useTranslate,
  useRecordContext,
  WrapperField,
  FunctionField,
  EmailField,
  TabbedShowLayout,
  Tab,
  ImageField,
  useShowContext,
  Loading,
  SelectField,
  useGetManyReference,
  ReferenceManyField,
  Datagrid,
} from "react-admin";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ReactEcharts from "echarts-for-react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CardWithTitle from "../../utils/CardWithTitle";
import CardWithSource from "../../utils/CardWithSource";

export const UserShow = () => {
  return (
    <Show emptyWhileLoading>
      <UserTabsShow></UserTabsShow>
    </Show>
  );
};

const ItemGrid = ({ record, source }) => {
  const translate = useTranslate();

  return (
    <Grid item xs={4} display="flex" gap={1}>
      <Box flexGrow={1}>
        <TextField
          variant="h5"
          emptyText="100"
          record={record}
          source={source}
        />
        <Typography variant="subtitle1">
          {translate(`resources.users.fields.${source}`)}
        </Typography>
      </Box>
    </Grid>
  );
};

const UserTabsShow = () => {
  const translate = useTranslate();
  const { isLoading, record } = useShowContext();
  const [data, setData] = React.useState({
    total: {
      activities: [],
      distance: [],
      calories: [],
    },
  });
  const optionCaloriesBar = {
    title: {
      text: "Calories by activity",
      left: "center",
    },
    xAxis: {
      type: "category",
      data: data.total.activities,
    },
    yAxis: {
      type: "value",
    },
    tooltip: {
      trigger: "item",
      formatter: "{b} : {c}",
    },
    series: [
      {
        data: data.total.calories,
        type: "bar",
      },
    ],
  };

  const optionDistanceBar = {
    title: {
      text: "Distance by activity",
      left: "center",
    },
    xAxis: {
      type: "category",
      data: data.total.activities,
    },
    yAxis: {
      type: "value",
    },
    tooltip: {
      trigger: "item",
      formatter: "{b} : {c}",
    },
    series: [
      {
        data: data.total.distance,
        type: "bar",
      },
    ],
  };

  React.useEffect(() => {
    if (record) {
      if (record.totalsByActivity) {
        record.total = {};
        record.total.activities = record.totalsByActivity.map(function (elem) {
          return elem.name;
        });

        record.total.calories = record.totalsByActivity.map(function (elem) {
          return elem.total_calories;
        });

        record.total.distance = record.totalsByActivity.map(function (elem) {
          return elem.total_distance;
        });
        setData(record);
      }
    }
  }, [record]);

  if (isLoading) {
    return <Loading></Loading>;
  }
  return (
    <TabbedShowLayout>
      <Tab label={translate("resources.users.tabs.details")}>
        <Grid container spacing={2} sx={{ margin: 2 }}>
          <Grid item xs={12} sm={12}>
            <FunctionField
              variant={"h5"}
              render={(record) => `${record.firstname} ${record.lastname}`}
            />
          </Grid>

          <Grid container mt={3} columnSpacing={3}>
            <Grid item xs={12} md={4}>
              <Labeled>
                <TextField label="Email" source="email" fullWidth />
              </Labeled>
            </Grid>

            <Grid item xs={12} md={4}>
              <Labeled>
                <TextField label="Cell" source="details.cell" fullWidth />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} md={4}>
              <Labeled>
                <SelectField
                  variant="standard"
                  source="details.sex"
                  fullWidth
                  choices={[
                    { id: "M", name: "Male" },
                    { id: "F", name: "Female" },
                  ]}
                />
              </Labeled>{" "}
            </Grid>
            <Grid item xs={12} md={4}>
              <Labeled>
                <TextField label="Height" source="details.height" fullWidth />
              </Labeled>{" "}
            </Grid>
            <Grid item xs={12} md={4}>
              <Labeled>
                <TextField label="Weight" source="details.weight" fullWidth />
              </Labeled>{" "}
            </Grid>
            <Grid item xs={12} md={4}>
              <Labeled>
                <TextField label="Country" source="details.country" fullWidth />
              </Labeled>
            </Grid>
			<Grid item xs={12} md={4}>
              <Labeled>
                <TextField label="Min Withdraw" source="details.min_widthdraw" fullWidth />
              </Labeled>
            </Grid>
			<Grid item xs={12} md={4}>
              <Labeled>
                <TextField label="Max per month" source="details.max_per_month" fullWidth />
              </Labeled>
            </Grid>
          </Grid>
        </Grid>

        <Box m={5}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <ItemGrid source="activities"></ItemGrid>
            <ItemGrid source="challenges"></ItemGrid>
            <ItemGrid source="achievements"></ItemGrid>
            <ItemGrid source="total_distance"></ItemGrid>
            <ItemGrid source="total_calories"></ItemGrid>
            <ItemGrid source="total_time"></ItemGrid>
          </Grid>
        </Box>

        <Box m={3}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={3}>
              <CardWithSource
                to=""
                icon={LocalAtmIcon}
                title={translate("resources.users.balance")}
                source="balance"
              />
            </Grid>
          </Grid>
        </Box>
      </Tab>
      <Tab label={translate("resources.users.tabs.activities")}>
        <Box>
          <Grid item xs={12} md={12}>
            <ReactEcharts option={optionCaloriesBar} />
          </Grid>
          <Grid item xs={12} md={12}>
            <ReactEcharts option={optionDistanceBar} />
          </Grid>
        </Box>
      </Tab>
      <OrdersTab>
          <ReferenceManyField label="" reference="withdrawalOrders" target="user_id">
            <Datagrid
              bulkActionButtons={false}
              
            >
              <DateField source="order_date"/>
              <TextField source="ammount" />
              <TextField source="status" />

              <DateField source="updated_at" />
            </Datagrid>
          </ReferenceManyField>
        </OrdersTab>
      <Tab label="Selfie">
        <ImageField source="url_selfie"></ImageField>
      </Tab>
      <Tab label="Doc Front">
        <ImageField source="url_pic_doc_front"></ImageField>
      </Tab>
      <Tab label="Doc Back">
        <ImageField source="url_pic_doc_back"></ImageField>
      </Tab>
    </TabbedShowLayout>
  );
};



const OrdersTab = (props) => {
  const record = useRecordContext();
  /*const { isLoading, total } = useGetManyReference(
    "worders",
    {
      target: "user_id",
      id: record.id,
      pagination: { page: 1, perPage: 25 },
      sort: { field: "id", order: "DESC" },
    },
    {
      enabled: !!record,
    }
  );*/
  const translate = useTranslate();
  let label = translate("resources.users.tabs.orders");

  return <Tab label={label} {...props} />;
};
