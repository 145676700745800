import { Edit as ReactAdminEdit, ListButton, TopToolbar } from "react-admin";

const Edit = ReactAdminEdit;

const Actions = () => (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  );
Edit.defaultProps = {
  mutationMode:"pessimistic",
  actions:<Actions />

};

export default Edit;