import { PolicyList } from './PolicyList';
import { PolicyEdit } from './PolicyEdit';
import { PolicyShow } from './PolicyShow';
import { PolicyCreate } from './PolicyCreate';
import GavelIcon from '@mui/icons-material/Gavel';

export default {
    edit: PolicyEdit,
    list: PolicyList,
    show: PolicyShow,
    create: PolicyCreate,
    icon: GavelIcon
};