import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ShowButton,
  DateField,
  NumberField,
  TextInput,
  FilterForm,
  FilterButton,
  CreateButton,
  SelectInput,
  EditButton,
  BooleanField,
  
  NullableBooleanInput,
  ExportButton,
  TopToolbar
} from "react-admin";
import { Stack } from "@mui/material";
import ContentFilter from '@mui/icons-material/FilterList';
import { useForm ,Controller} from 'react-hook-form';
import { Box, Button, InputAdornment,TextField as TextFieldMaterial} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useListContext } from 'react-admin';

export const UserList = (props) => (
  <List {...props}  sort={{ field: 'validated_at', order: 'ASC' }} actions={<TopToolbar/>} >
    <ListToolbar/>    
    <Datagrid rowClick="show" bulkActionButtons={false} >
      <TextField source="firstname" />
      <TextField source="lastname" />
      <TextField source="age" />
      <TextField source="details.sex" />
      <TextField source="device_platform" sortable={false} />
      <DateField source="validated_at" />
      <DateField source="updated_at" />
      <NumberField source="calorie_coins" label="BPMs" sortable={false}/>
      <BooleanField  source="validated" sortable={false}/>
	<TextField label="Invitation Code"  source="details.invitation_code" sortable={false}/>
      <ShowButton></ShowButton>
      <EditButton></EditButton>
    </Datagrid>
  </List>
);

const userFilters = [
    

  <TextInput label="Search" source="q" alwaysOn />,
  <TextInput label="Min Age" source="min-age" defaultValue="21" />,
  <TextInput label="Max Age" source="max-age" defaultValue="99" />,
  <TextInput label="Geographic location" source="location" defaultValue="" />,
  <SelectInput
    source="sex"
    choices={[
      { id: "F", name: "F" },
      { id: "M", name: "M" },
    ]}
  />,
];

const ListToolbar = () => (
  <Stack direction="row" justifyContent="space-between">
    <FilterForm filters={userFilters} />
   <UserFilterForm /> 

    <div>
      <FilterButton filters={userFilters} />
    {/* <UserFilterButton /> */}

    </div>
  </Stack>
  
);



const UserFilterButton = () => {
  const { showFilter } = useListContext();
  return (
      <Button
          size="small"
          color="primary"
          onClick={() => showFilter("main")}
          startIcon={<ContentFilter />}
      >
          Age Filter
      </Button>
  );
};


const UserFilterForm = () => {
  const {
      displayedFilters,
      filterValues,
      setFilters,
      hideFilter
  } = useListContext();


  const  { handleSubmit,control }  = useForm({
      defaultValues: filterValues,
  });

  if (!displayedFilters.main) return null;

  const onSubmit = (values) => {
      if (Object.keys(values).length > 0) {
          setFilters(values);
      } else {
          hideFilter("main");
      }
  };

  const resetFilter = () => {
      setFilters({}, []);
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="row" m={1}>
              <Box component="span" >
              <Controller
                            name="min-age"
                            render={({ field }) => (
                                <TextFieldMaterial label="Min Age" {...field} />
                            )}
                            control={control}
                        /> 
                         <Controller
                            name="max-age"
                            render={({ field }) => (
                                <TextFieldMaterial label="Max Age" {...field} />
                            )}
                            control={control}
                        /> 
              </Box>
            
              <Box component="span" ml={1} mr={1} >
                  <Button variant="outlined" color="primary" type="submit">
                      <SearchIcon></SearchIcon>
                  </Button>
              </Box>
              <Box component="span"  mr={1}>
                  <Button variant="outlined" onClick={resetFilter}>
                      Close
                  </Button>
              </Box>
          </Box>
      </form>
  );
};

