import { stringify } from 'query-string';


const AxiosDataProvider = (
    apiUrl,
    httpClient
) => ({
    getList: (resource, params) => {

        const { page, perPage } = params.pagination;

        // Create query with pagination params.
        const query = {
            'page': page,
            'per_page': perPage,
        };

        // Add all filter params to query.
        Object.keys(params.filter || {}).forEach((key) => {
            query[`filter[${key}]`] = params.filter[key];
        });

        // Add sort parameter
        if (params.sort && params.sort.field) {
            const prefix = params.sort.order === 'ASC' ? '' : '-';
            query.sort = `${prefix}${params.sort.field}`;
        }

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then((response) => {
            let total;
            if (response.data.meta) {
                total = response.data.meta['total'];
            }else{
                if(response.data.total)
                    total = response.data.total;
            }

            total = total || response.data.data.length;
            return {
                data: response.data.data,
                total,
            };
        });
    },

    getOne: (resource, params) => {
        let url = `${apiUrl}/${resource}`;
        if (params.id) {
            url += `/${params.id}`;
        }
        if (params.filter) {
            // Add all filter params to query.
            let query = {};
            Object.keys(params.filter || {}).forEach((key) => {
                query[`filter[${key}]`] = params.filter[key];
            });
            url += `?${stringify(query)}`;
        }

        return httpClient(url).then((response) => ({
            data: { ...response.data.data, 'id': response.data.data.id }
        }));
    },
    getMany: (resource, params) => {
        const query = stringify({
            'filter[id]': params.ids,
        }, { arrayFormat: 'brackets' });

        const url = `${apiUrl}/${resource}?${query}`;
        return httpClient(url).then((response) => {
            let total;
            if (response.data.meta) {
                total = response.data.meta['total'];
            }else{
                if(response.data.total)
                    total = response.data.total;
            }

            total = total || response.data.data.length;

            return {
                data: response.data.data,
                total,
            };
        });
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;

        // Create query with pagination params.
        const query = {
            'page': page,
            'per_page': perPage,
        };

        // Add all filter params to query.
        Object.keys(params.filter || {}).forEach((key) => {
            query[`filter[${key}]`] = params.filter[key];
        });

        // Add the reference id to the filter params.
        query[`filter[${params.target}]`] = params.id;

        // Add sort parameter
        if (params.sort && params.sort.field) {
            const prefix = params.sort.order === 'ASC' ? '' : '-';
            query.sort = `${prefix}${params.sort.field}`;
        }

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then((response) => {
            let total;
            if (response.data.meta) {
                total = response.data.meta['total'];
            }

            total = total || response.data.data.length;

            return {
                data: response.data.data,
                total,
            };
        });
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PATCH',
            data: JSON.stringify(params.data),
        }).then((response) => ({
            data: response.data.data
        })),

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    data: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            data: JSON.stringify(params.data),
        }).then((response) => ({
            data: response.data.data,
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',

        }).then(( response ) => ({ data: response.data.data })),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Content-Type': 'text/plain',
                    }),
                })
            )
        ).then(responses => ({
            data: responses.map(({ json }) => json.id),
        })),
    get: (resource, params) => {
        let url = `${apiUrl}/${resource}`;

        if (params.filter) {
            // Add all filter params to query.
            let query = {};
            Object.keys(params.filter || {}).forEach((key) => {
                query[`filter[${key}]`] = params.filter[key];
            });
            url += `?${stringify(query)}`;

        }else{
            if(params){
                url += `?${stringify(params)}`;

            }
        }


        return httpClient(url).then((response) => {
            if (response.data.data && response.data.data.id)
                return { data: { ...response.data.data, 'id': response.data.data.id } }
            else{
                if(response.data.data)
                    return { data: { ...response.data.data} }

                else
                    return { data: {} }
            }
           

        });
    },
    getListAll: (resource, params) => {
        let url = `${apiUrl}/${resource}`;

        if (params.filter) {
            // Add all filter params to query.
            let query = {};
            Object.keys(params.filter || {}).forEach((key) => {
                query[`filter[${key}]`] = params.filter[key];
            });
            url += `?${stringify(query)}`;

        }


        return httpClient(url).then((response) => {
            if (response.data.data)
                return { data: response.data.data}
            else
                return { data: {} }

        });
    },
});


export default AxiosDataProvider