import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {
  TextInput,
  Form,
  SaveButton,
  ReferenceInput,
  SelectInput,
  DateInput,
  required,
  ImageInput,
  Labeled,
  number,
  NumberInput,
  useRecordContext,
  FormDataConsumer,
  AutocompleteInput,
  useDataProvider,
} from "react-admin";
import { PreviewImage } from "../../utils/PreviewImage";
//import ColorSelector from "../../utils/ColorSelector";
import { useWatch } from 'react-hook-form';

const validateTargetsInputs = (values) => {
  const errors = {};

  if (
    !values.target_duration &&
    !values.target_calories &&
    !values.target_distance
  ) {
    errors.target_duration = "At least one target is required";
    errors.target_calories = "At least one target is required";
    errors.target_distance = "At least one target is required";
  }

  return errors;
};

const CityInput = props => {
  const country = useWatch({ name: 'country_id' });
  const dataProvider = useDataProvider();
  const [calles, setCalles] = React.useState([]);
  
    
  React.useEffect(() => {
    if (country) {
        dataProvider.getListAll('cities', { filter: { 'country_id': country } })
            .then(({ data }) => {
                if (data)
                    setCalles(data);
                else
                    setCalles([]);

            });
    }
}, [country]);

  return (
      <SelectInput
          choices={calles}
          {...props}
      />
  );
};

export const FormChallenge = () => {

    return (
      <Container>
        <Form validate={validateTargetsInputs}>
          <Grid container spacing={2} columnSpacing={3}>
            <Grid item md={12}>
              <TextInput
                variant="standard"
                autofocus
                source="name"
                fullWidth
                validate={required()}
              />
            </Grid>
            <Grid item md={6}>
              <ReferenceInput
                source="activity_type_id"
                reference="activityTypes"
                validate={required()}
              >
                <SelectInput
                  variant="standard"
                  fullWidth
                  optionText="name"
                  validate={required()}
                />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <ReferenceInput source="charity_id" reference="charities">
                <SelectInput variant="standard" fullWidth optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item md={4}>
              <TextInput
                variant="standard"
                fullWidth
                source="target_duration"
              ></TextInput>
            </Grid>
            <Grid item md={4}>
              <TextInput
                variant="standard"
                fullWidth
                source="target_distance"
              ></TextInput>
            </Grid>

            <Grid item md={4}>
              <TextInput
                variant="standard"
                fullWidth
                source="target_calories"
              ></TextInput>
            </Grid>

            <Grid item md={3}>
              <NumberInput
                variant="standard"
                fullWidth
                source="timeframe"
                validate={required()}
              ></NumberInput>
            </Grid>

            <Grid item md={3}>
              <TextInput
                variant="standard"
                fullWidth
                source="coin_award"
                validate={required()}
              ></TextInput>
            </Grid>
            <Grid item md={3}>
              <TextInput
                variant="standard"
                fullWidth
                source="join_price"
                validate={required}
              ></TextInput>
            </Grid>
            <Grid item md={3}>
              <TextInput
                variant="standard"
                fullWidth
                source="stock_award"
                validate={required}
              ></TextInput>
            </Grid>
            <Grid item md={6}>
              <DateInput
                variant="standard"
                fullWidth
                source="public_init_date"
                validate={required()}
              ></DateInput>
            </Grid>
            <Grid item md={6}>
              <DateInput
                variant="standard"
                fullWidth
                source="public_end_date"
                validate={required()}
              ></DateInput>
            </Grid>
            <Grid item md={6}>
              <ReferenceInput source="country_id" reference="countries">
                <SelectInput variant="standard" fullWidth optionText="name" />
              </ReferenceInput>
            </Grid>
           
            
              <Grid item md={6}>
               
               <CityInput source="city_id"  variant="standard" ></CityInput>
               
              </Grid>
            <Grid item xs={10}>
              <ImageInput
                label="Logo"
                source="url_picture"
                accept="image/*"
                validate={required()}
              >
                <PreviewImage></PreviewImage>
              </ImageInput>
            </Grid>

            <Grid item xs={10}>
              <ImageInput
                label="Background Image"
                source="url_background"
                accept="image/*"
                validate={required()}
              >
                <PreviewImage></PreviewImage>
              </ImageInput>
            </Grid>

            <Grid item xs={4}>
			 <TextInput
                variant="standard"
                fullWidth
                source="header_color"
              ></TextInput>
            </Grid>
            <Grid item xs={4}>
				<TextInput
                variant="standard"
                fullWidth
                source="footer_color"
              ></TextInput>  
            </Grid>

            <Grid item md={12} mb={4}>
              <SaveButton />
            </Grid>
          </Grid>
        </Form>
      </Container>
    );
  
};
