import * as React from "react";
import { List, Datagrid, TextField, EmailField, ShowButton, EditButton, TextInput, FilterForm, DeleteWithConfirmButton,
  FunctionField } from 'react-admin';
import { Stack } from "@mui/material";

export const CharityList = () => (
    <List empty={false}>
        <ListToolbar/>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="balance"/>
            <TextField source="total_donations"/>
            <TextField source="worder_pending"/>
            <TextField source="worder_released"/>
            
            <ShowButton ></ShowButton>
            <EditButton ></EditButton>
			<DeleteWithConfirmButton label={false}/>
        </Datagrid>
    </List>
);



const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between">
      <FilterForm filters={adminFilters} />  
      {/* <div>
        <FilterButton filters={adminFilters} />
      </div> */}
    </Stack>
  );
  
  
  const adminFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
   
  ];
  