import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ReferenceField,
  DateField,
  DeleteWithConfirmButton,
  FunctionField,
} from "react-admin";

export const ChallengeList = () => (
  <List empty={false}>
    <Datagrid rowClick="show" bulkActionButtons={false} size='small'>
      <ReferenceField source="activity_type_id" reference="activityTypes">
        <TextField source="name"></TextField>
      </ReferenceField>
      <TextField source="name" />
      <DateField source="public_init_date"></DateField>
      <DateField source="public_end_date"></DateField>
      <ReferenceField source="charity_id" reference="charities">
        <TextField source="name"></TextField>
      </ReferenceField>
      <TextField source="coin_award" />    
      <TextField source="stock_award" />    
      <TextField source="balance_award" />    
      <FunctionField source="available" render={row => {return row.stock_award-row.balance_award}} />    
      <TextField source="users" />    
      <ShowButton sx={{'m':1}} label=""></ShowButton>
      <EditButton label={false}></EditButton>
      <DeleteWithConfirmButton label={false}/>
    </Datagrid>
  </List>
);
