import {CountryList} from './CountryList';
import {CountryEdit} from './CountryEdit';
import { CountryShow } from './CountryShow';
import { CountryCreate } from './CountryCreate';
import FlagIcon from '@mui/icons-material/Flag';
export default {
    edit:CountryEdit,
    list:CountryList,
    show:CountryShow,
    create:CountryCreate,
    icon:FlagIcon
};