import * as React from "react";
import {
  Datagrid,
  TextField,  
  ReferenceField,
  DateField,
  FunctionField,
  useGetList,
  useList,
  ListContextProvider,
} from "react-admin";



export const AvailableChallengeList = () => {
  const { data, isLoading } = useGetList(
    'challenges',
    { page: 1, perPage: 10, filter:{status:'available'} }
  );
  const listContext = useList({ data, isLoading });
  return (
    <ListContextProvider value={listContext}>
        
    <Datagrid bulkActionButtons={false} size='small'>
      <ReferenceField source="activity_type_id" reference="activityTypes">
        <TextField source="name"></TextField>
      </ReferenceField>
      <TextField source="name" />
      <DateField source="public_init_date"></DateField>
      <DateField source="public_end_date"></DateField>
      <ReferenceField source="charity_id" reference="charities">
        <TextField source="name"></TextField>
      </ReferenceField>
      <TextField source="coin_award" />    
      <TextField source="stock_award" />    
      <TextField source="balance_award" />    
      <FunctionField source="available" render={row => {return row.stock_award-row.balance_award}} />    
      <TextField source="users" />    
    </Datagrid>
  </ListContextProvider>
)
  };
