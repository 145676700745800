import * as React from "react";
import Grid from "@mui/material/Grid";
import { Show, TextField, DateField, Labeled, ImageField, BooleanField, SelectField, TopToolbar, Button} from "react-admin";

export const WOrdersShow = () => (
  <Show emptyWhileLoading actions={<TopToolbar><Button>Process</Button><Button>Cancel</Button></TopToolbar>}>
    <Grid container spacing={2} sx={{ margin: 2 }}>
      
      <Grid item xs={12} sm={2}>
        <Labeled>
          <TextField source="id" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Labeled>
          <TextField source="name" />
        </Labeled>
      </Grid>
      
   
      <Grid item xs={12} sm={3}>
        <Labeled>
          <DateField source="order_date" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Labeled>
          <TextField source="ammount" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled>
          <SelectField
            variant="standard"
            source="status"
            fullWidth
            choices={[
              { id: "N", name: "Created" },
              { id: "R", name: "Released" },
              { id: "C", name: "Canceled" },
            ]}
          />
        </Labeled>
      </Grid>
      
     
    </Grid>
  </Show>
);
