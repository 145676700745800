import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TextInput,
  Form,
  SaveButton,
  ImageInput,
  required,
  email,
  useRecordContext,
  TabbedForm,
  FormTab,
  ImageField,
  Toolbar,
  BooleanInput,
  number,
  DateInput,
  SelectInput
} from "react-admin";
import { PreviewImage } from "../../utils/PreviewImage";

const ValidateButton = () => {
  return <Button>Validate</Button>;
};

export const ToolbarUser = () => {
  const record = useRecordContext();
  const handleValidation = () => {
    console.log(record);
  };
  return (
    <Toolbar>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={4}>
          <SaveButton label="Save" />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export const FormUser = () => {
  return (
    <TabbedForm toolbar={<ToolbarUser />}>
      <FormTab label="Details">
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={4}>
            <TextInput
              variant="standard"
              autofocus
              source="firstname"
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              variant="standard"
              source="lastname"
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DateInput
              variant="standard"
              source="details.birth_date"
              fullWidth
              validate={required()}
            />
          </Grid>
          
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={4}>
            <TextInput
              variant="standard"
              source="email"
              fullWidth
              validate={[email(), required()]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextInput
              variant="standard"
              source="details.cell"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={4}>
          <SelectInput
            variant="standard"
            source="details.sex"
            fullWidth
            validate={required()}
            choices={[
              { id: "M", name: "Male" },
              { id: "F", name: "Female" },
            ]}
          />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              variant="standard"
              source="details.height"
              fullWidth
              validate={number()}
             
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              variant="standard"
              source="details.weight"
              fullWidth
              validate={number()}
             
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              variant="standard"
              source="details.country"
              fullWidth
             
            />
          </Grid>
		  <Grid item xs={12} md={4}>
            <TextInput
              variant="standard"
              source="details.min_widthdraw"
              fullWidth
             
            />
          </Grid>
		  <Grid item xs={12} md={4}>
            <TextInput
              variant="standard"
              source="details.max_per_month"
              fullWidth
             
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item m={3} xs={12} md={4}>
            <BooleanInput label="Validated" source="validated" />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Selfie">
        <ImageField source="url_selfie" className="w90"></ImageField>
      </FormTab>
      <FormTab label="Doc Front">
        <ImageField source="url_pic_doc_front" className="w90"></ImageField>
      </FormTab>
      <FormTab label="Doc Back">
        <ImageField source="url_pic_doc_back" className="w90"></ImageField>
      </FormTab>
    </TabbedForm>
  );
};
