import * as React from "react";
import { useNotify, useRedirect } from "react-admin";
import Create from "../../baseComponents/Create";

import { FormActivityType } from "./FormActivityType";

const transformData = (data) => {
  if (data.url_icon.rawFile)
    data = {
      ...data,
      iconFile: data.url_icon,
    };
  return data;
};

export const ActivityTypeCreate = (props) => {
  const { callback } = props;
  const redirect = useRedirect();
  const notify = useNotify();
  const onSuccess = (data) => {
    if (callback) {
      callback(data);
    } else {
      notify('ra.notification.created');

      redirect("list", "activityTypes", data.id, data);
    }
  };
  return (
    <Create mutationOptions={{ onSuccess }} transform={transformData}>
      <FormActivityType></FormActivityType>
    </Create>
  );
};
