import * as React from "react";
import { useNotify, useRedirect } from "react-admin";
import Create from "../../baseComponents/Create";

import { FormWOrders } from "./FormWOrders";



export const WOrdersCreate = (props) => {
  const { callback } = props;
  const redirect = useRedirect();
  const notify = useNotify();
  const onSuccess = (data) => {
    if (callback) {
      callback(data);
    } else {
      notify('ra.notification.created');
      redirect("list", "withdrawalOrders", data.id, data);
    }
  };
  return (
    <Create mutationOptions={{ onSuccess }} >
      <FormWOrders></FormWOrders>
    </Create>
  );
};
