import * as React from "react";
import Grid from "@mui/material/Grid";
import { Show, TextField, DateField, Labeled, ImageField } from "react-admin";
import { RichTextField } from 'react-admin';

export const PolicyShow = () => ( <Show emptyWhileLoading>
    <Grid container spacing = { 2 }
    sx = {
        { margin: 2 } }>
    <Grid item xs = { 12 }
    sm = { 6 }>
    <Labeled>
    <TextField source = "version" />
    </Labeled> </Grid> <Grid item xs = { 12 }
    sm = { 6 }>
    <DateField source = "created_at" />
    </Grid> <Grid item xs = { 12 }
    sm = { 12 }>
    <Labeled>
    <RichTextField source = "description" />
    </Labeled> </Grid> </Grid> </Show>
);