import * as React from "react";
import Create from "../../baseComponents/Create";


import { FormAchievement } from "./FormAchievement";

const transformData = (data) => {
  if (data.url_icon.rawFile)
    data = {
      ...data,
      iconFile: data.url_icon,
    };
  return data;
};
export const AchievementCreate = () => (
  <Create transform={transformData} mutationMode="pessimistic">
    <FormAchievement></FormAchievement>
  </Create>
);
