// in src/Settings.js
import * as React from "react";
import { Card, CardContent } from "@mui/material";
import { Edit, required, SimpleForm, TextInput, Title } from "react-admin";
import { FormCharity } from "./FormCharity";

const ProfileCharity = (props) => {
    let {id} = props;
    return (
  <Card>
    <Title title="Profile" />
    <CardContent>
      <Edit
        id={id}
        resource="charities/me"
        basePath="/profile"
        redirect={false}
        title="My profile"
        actions={false}
        
      >
      <FormCharity></FormCharity>
      </Edit>
    </CardContent>
  </Card>
);
}

export default ProfileCharity;
