import {
  Loading,
  Title,
  useDataProvider,
  usePermissions,
  useTranslate,
} from "react-admin";
import Grid from "@mui/material/Grid";
import CardWithIcon from "./utils/CardWithIcon";
import DollarIcon from "@mui/icons-material/AttachMoney";
import UserIcon from "@mui/icons-material/People";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { AvailableChallengeList } from "./resources/challenge/AvailableChallengeList";
const Dashboard = (props) => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) return <Loading></Loading>;

  if (permissions.tieneRol("charityUser")) {
    return <DashboardCharity></DashboardCharity>;
  } else {
    return <DashboardAdmin></DashboardAdmin>;
  }
};

const DashboardAdmin = () => {
  const translate = useTranslate();
  const [data, setData] = useState({
    users: 0,
    charities: 0,
    total_activities_by_type: [],
    totalCaloriesbyActivityType: [],
    totalCalories: 0,
    total_devices_by_platform: [],
  });
  const [loaded, setLoaded] = useState(false);
  const dataProvider = useDataProvider();
  const optionAcitivitiesPie = {
    title: {
      text: "# by activity",
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "# by activity",
        type: "pie",
        radius: "50%",
        data: data.total_activities_by_type,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  const optionPlatformPie = {
    darkMode: "auto",
    title: {
      text: "# by platform",
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "# by platform",
        type: "pie",
        radius: "50%",
        data: data.total_devices_by_platform,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const optionCaloriesBar = {
    title: {
      text: "Calories by activity - Total: " + data.totalCalories,
      left: "center",
    },
    xAxis: {
      type: "category",
      data: data.totalCaloriesbyActivityType.map(function (elem) {
        return elem.name;
      }),
    },
    yAxis: {
      type: "value",
    },
    tooltip: {
      trigger: "item",
      formatter: "{b} : {c}",
    },
    series: [
      {
        data: data.totalCaloriesbyActivityType.map(function (elem) {
          return elem.value;
        }),
        type: "bar",
      },
    ],
  };

  useEffect(() => {
    dataProvider.get("dashboard", {}).then((resp) => {
      if (resp.data) {
        setData(resp.data);
        setLoaded(true);
      }
    });
  }, []);

  if (!data) {
    return <Loading></Loading>;
  }

  if (!loaded) return <Loading></Loading>;

  return (
    <>
      <Title title="" />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 5, pb: 5 }}
      >
        <Grid container spacing={4} pb={5}>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={DollarIcon}
              title={translate("dashboard.estimated_price")}
              subtitle={"USD " + data.BPM_quotation}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={CurrencyBitcoinIcon}
              title={translate("dashboard.BPM_balance")}
              subtitle={data.BPM_balance}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={CurrencyBitcoinIcon}
              title={translate("dashboard.BPM_available")}
              subtitle={data.BPM_available}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={AccessibilityIcon}
              title={translate("dashboard.total_charities")}
              subtitle={data.charities}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={UserIcon}
              title={translate("dashboard.total_users")}
              subtitle={data.users}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={UserIcon}
              title={translate("dashboard.total_active_users")}
              subtitle={data.totalActiveUsers}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={DollarIcon}
              title={translate("dashboard.total_delivered_users")}
              subtitle={Number(
                Math.round(
                  data.total_delivered_users * data.BPM_quotation + "e4"
                ) + "e-4"
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={DollarIcon}
              title={translate("dashboard.total_delivered_charities")}
              subtitle={Number(
                Math.round(
		//data.total_delivered_charities * data.BPM_quotation + "e4"
                  data.total_delivered_users * data.BPM_quotation + "e4"
                ) + "e-4"
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={ReceiptIcon}
              title={translate("dashboard.total_pending_withdraw_orders")}
              subtitle={Number(data.total_pending_withdraw_orders)}
            />
          </Grid>
        </Grid>
      </Grid>

      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ReactEcharts option={optionAcitivitiesPie} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ReactEcharts option={optionPlatformPie} />
            </Grid>

            <Grid item xs={12} md={12}>
              <ReactEcharts option={optionCaloriesBar} />
            </Grid>
            <Grid item xs={12} pb={4}>
              <>
                <h4>{translate("dashboard.active_challenges")}</h4>
                <AvailableChallengeList></AvailableChallengeList>
              </>
            </Grid>
          </Grid>
        </Grid>
      </>
    </>
  );
};

const DashboardCharity = () => {
  const translate = useTranslate();
  const [data, setData] = useState({
    users: 0,
    charities: 0,
    total_activities_by_type: [],
    totalCaloriesbyActivityType: [],
    totalCalories: 0,
    total_devices_by_platform: [],
  });
  const [loaded, setLoaded] = useState(false);
  const dataProvider = useDataProvider();


  useEffect(() => {
    dataProvider.get("dashboard", {}).then((resp) => {
      if (resp.data) {
        setData(resp.data);
        setLoaded(true);
      }
    });
  }, []);

  if (!data) {
    return <Loading></Loading>;
  }

  if (!loaded) return <Loading></Loading>;

  return (
    <>
      <Title title="" />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 5, pb: 5 }}
      >
        <Grid container spacing={4} pb={5} alignItems={'center'}>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={DollarIcon}
              title={translate("dashboard.estimated_price")}
              subtitle={"USD " + data.BPM_quotation}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={CurrencyBitcoinIcon}
              title={translate("dashboard.BPM_balance")}
              subtitle={data.BPM_balance}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardWithIcon
              to=""
              icon={CurrencyBitcoinIcon}
              title={translate("dashboard.BPM_available")}
              subtitle={data.BPM_available}
            />
          </Grid>

        </Grid>
       
      </Grid>
    </>
  );
};

export default Dashboard;
