import {CharityList} from './CharityList';
import { CharityShow } from './CharityShow';
import {CharityCreate} from './CharityCreate';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { CharityEdit } from './CharityEdit';

export default {
    list:CharityList,
    show:CharityShow,
    create:CharityCreate,
    edit:CharityEdit,
    icon:AccessibilityIcon
};