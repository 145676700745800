import * as React from "react";

import Create from "../../baseComponents/Create";
import { FormCity } from "./FormCity";

export const CityCreate = () => {
 
  
  return (
    <Create >
      <FormCity></FormCity>
    </Create>
  );
};
