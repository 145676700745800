import {AchievementList} from './AchievementList';
import { AchievementShow } from './AchievementShow';
import {AchievementCreate} from './AchievementCreate';
import { AchievementEdit } from './AchievementEdit';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
export default {
    list:AchievementList,
    show:AchievementShow,
    create:AchievementCreate,
    edit:AchievementEdit,
    icon:EmojiEventsIcon
};