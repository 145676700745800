import {ActivityTypeList} from './ActivityTypeList';
import { ActivityTypeShow } from './ActivityTypeShow';
import {ActivityTypeCreate} from './ActivityTypeCreate';
import { ActivityTypeEdit } from './ActivityTypeEdit';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';

export default {
    list:ActivityTypeList,
    show:ActivityTypeShow,
    create:ActivityTypeCreate,
    edit:ActivityTypeEdit,
    icon:SportsHandballIcon
};