import * as React from "react";
import Grid from "@mui/material/Grid";
import { Show, TextField, DateField, Labeled, ImageField } from "react-admin";

export const CityShow = () => (
  <Show emptyWhileLoading>
    <Grid container spacing={2} sx={{ margin: 2 }}>
      <Grid item xs={12} sm={6}>
        <Labeled>
          <TextField source="name" />
        </Labeled>
      </Grid>
    
      <Grid item xs={12} sm={6}>
        <DateField source="created_at" />
      </Grid>
    </Grid>
  </Show>
);
