import { CreateButton, ExportButton, List as ReactAdminList, TopToolbar } from "react-admin";

const List = ReactAdminList;

const Actions = () => (
    <TopToolbar>
      <CreateButton />
      <ExportButton/>
    </TopToolbar>
  );
List.defaultProps = {
  actions:<Actions />,


};

export default List;