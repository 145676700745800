import * as React from "react";
import { List, Datagrid, TextField, ShowButton, DateField, EditButton } from 'react-admin';

export const CountryList = () => (
    <List>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="name" />
            <DateField source="created_at" />
            <ShowButton ></ShowButton>
            <EditButton></EditButton>

        </Datagrid>
    </List>
);

  