import {CalorieCoefficientList} from './CalorieCoefficientList';

import { CalorieCoefficientShow } from './CalorieCoefficientShow';
import { CalorieCoefficientCreate } from './CalorieCoefficientCreate';
import { CalorieCoefficientEdit } from './CalorieCoefficientEdit';
import PercentIcon from '@mui/icons-material/Percent';

export default {
    list:CalorieCoefficientList,    
    show:CalorieCoefficientShow,
    create:CalorieCoefficientCreate,
    edit:CalorieCoefficientEdit,
    icon:PercentIcon
};