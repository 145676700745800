import {WOrdersList} from './WOrdersList';

import { WOrdersShow } from './WOrdersShow';
import { WOrdersCreate } from './WOrdersCreate';
import { WOrdersEdit } from './WOrdersEdit';
import PaidIcon from '@mui/icons-material/Paid';

export default {
    list:WOrdersList,    
    show:WOrdersShow,
    create:WOrdersCreate,
    edit:WOrdersEdit,
    icon:PaidIcon
};