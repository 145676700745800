import {UserList} from './UserList';
import { UserShow } from './UserShow';

import UserIcon from '@mui/icons-material/People';
import { UserEdit } from './UserEdit';

export default {
    list:UserList,
    show:UserShow,
    edit:UserEdit,
    icon:UserIcon
};