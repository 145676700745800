import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  DateField,
  EditButton,
  ReferenceField,
} from "react-admin";

export const CityList = () => (
  <List>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <ReferenceField source="country_id" reference="countries">
        <TextField source="name"></TextField>
      </ReferenceField>
      <DateField source="created_at" />
      <ShowButton></ShowButton>
      <EditButton></EditButton>
    </Datagrid>
  </List>
);
