
import axios from 'axios';
import AxiosDataProvider from './AxiosDataProvider';


export class HttpError extends Error {
    constructor(message, status) {
        super(message);

        this.message = message;
        this.status = status;
        this.name = 'HttpError';
    }
}

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8',
        };
    }
    return axios({ url, withCredentials: true, baseURL: process.env.REACT_APP_URL_API, ...options })
}


axios.interceptors.response.use(
    response => response,
    (error) => {

        if (error.response && error.response.status) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            let { status, data } = error.response;

            /*if (status == 401) {
                console.log("sin sesion");
              localStorage.removeItem('auth');
              localStorage.removeItem('roles');
              window.location("/login");
      
            }*/
            if (status < 200 || status >= 300) {
                if(status == 422){
                    if (!data )
                        data = "Error del servidor";
                        else
                            data = data.message;
                    return Promise.reject(
                        new HttpError(data, status),
                    );
                }else{
                    console.log('error en dataProvider', error);
                    if (!data || typeof data !== "string")
                        data = "Error del servidor";
                    return Promise.reject(
                        new HttpError(data, status),
                    );
                }
                
            }
           
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            error = new HttpError('Sin conexion', 500)

        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            return Promise.reject(
                new HttpError('Error del servidor', 500)
            );

        }

        return Promise.reject(error);
    },
);
const dataProvider = AxiosDataProvider(process.env.REACT_APP_API_URL, httpClient);
const CustomDataProvider = {
    ...dataProvider,
    uploadNewFile: (resource, params) => {

        // Freshly dropped files are File objects and must be converted to binary strings
        const newFiles = params.data.files.filter(
            p => p.rawFile instanceof File
        );
        const formerFiles = params.data.files.filter(
            p => !(p.rawFile instanceof File)
        );

        return Promise.all(newFiles.map(convertFileToBinary))
            .then(binaryfiles =>
                binaryfiles.map((file, i) => ({
                    src: file,
                    name: `file[${i}]`,
                    title: `${params.data.title}`,
                }))
            )
            .then(transformedNewFiles =>
                dataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        files: [
                            ...transformedNewFiles,
                            ...formerFiles,
                        ],
                    },
                })
            );
    },
    create: (resource, params) => {
        if (!params.data || !hasFiles(params.data)) {
            // fallback to the default implementation
            return dataProvider.create(resource, params);
        }
        const formData = getFormData(params.data);

        return httpClient(`${process.env.REACT_APP_API_URL}/${resource}`, {
            method: 'POST',
            data: formData,
        }).then((response) => ({
            data: { ...response.data.data },
        }));
    },
    update: (resource, params) => {
        if (!params.data || !hasFiles(params.data)) {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }

        const formData = getFormData(params.data);
        formData.append('_method', 'PUT')  //envio por post pero agrego _method para que laravel redireccione a put

        return httpClient(`${process.env.REACT_APP_API_URL}/${resource}/${params.id}`, {
            method: 'POST',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }

        }).then((response) => ({
            data: response.data.data
        }));
    },
    restore: (resource, params) => {
        
       return httpClient(`${process.env.REACT_APP_API_URL}/${resource}/${params.id}/restore`, {
            method: 'PATCH',
            data: JSON.stringify(params.data),
        }).then((response) => ({
            data: response.data.data
        }));
    }

};



const convertFileToBinary = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsBinaryString(file.rawFile);
    });


const hasFiles = (data) => {
    let hasFiles = false;

    Object.entries(data).forEach(([key, value]) => {
        if (value && value.rawFile instanceof File) {
            hasFiles = true;
        }
    });

    return hasFiles;
}

const getFormData = (data) => {
    let formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
        if (value && value.rawFile instanceof File) {
            formData.append(key, value.rawFile);
        }
    });
    formData.append('dataJson', JSON.stringify(data));

    
    return formData;
}

export default CustomDataProvider;