import { Create as ReactAdmincreate } from "react-admin";

const Create = ReactAdmincreate;


Create.defaultProps = {
  redirect:"list",
  mutationMode:"pessimistic"


};

export default Create;