// in src/posts.js
import * as React from "react";
import { useNotify } from "react-admin";
import Edit from "../../baseComponents/Edit";
import { FormCharity } from "./FormCharity";

export const CharityEdit = () => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("ra.action.updated");
  };

  const transformData = (data) => {
    if (data.url_logo && data.url_logo.rawFile)
      data["logoFile"] = data.url_logo;

    if (data.url_imagen && data.url_imagen.rawFile)
      data["imagenFile"] = data.url_imagen;
      
    if (data.url_letter && data.url_letter.rawFile)
      data["letterFile"] = data.url_letter;

    return data;
  };

  return (
    <Edit onSuccess={onSuccess} transform={transformData}   mutationMode="pessimistic">
      <FormCharity></FormCharity>
    </Edit>
  );
};
