import * as React from "react";
import { List, Datagrid, TextField, EmailField, ShowButton, EditButton, BooleanField } from 'react-admin';

export const MeasureUnitList = () => (
    <List empty={false}>
        <Datagrid  bulkActionButtons={false} >
            
            <TextField source="name" />
            <TextField source="description" />
           
        </Datagrid>
    </List>
);